<template>
    <section id="landscape-orientation-warning">
        <article id="message-container">
            <q-img src="@/assets/images/rotate-device.svg" id="rotation-image" no-spinner />
            <p id="rotate-device-message-text" class="q-mt-md">
                Розверніть свій девайс у вертикальне положення
            </p>
        </article>
    </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "LandscapeOrientationWarning",
    props: {
        minWidth: {
            type: String,
            required: false,
        },
        minHeight: {
            type: String,
            required: false,
        },
    },
});
</script>

<style lang="scss" scoped>
#landscape-orientation-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $core-white-color-100;
}

#message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#rotation-image {
    width: 126px;
    height: 128px;
}

#rotate-device-message-text {
    color: $core-black-color-100;
    font-weight: $core-bold;
    font-size: to-rem(24);
    text-align: center;
    width: 100%;
    white-space: nowrap;
}
</style>
