<template>
    <q-layout class="fullheight" view="lHh Lpr lFf">
        <landscape-orientation-warning class="absolute-center" id="orientation-warning" />
        <section id="main-content">
            <router-view></router-view>
        </section>
    </q-layout>
</template>

<script lang="ts">
import LandscapeOrientationWarning from "@/common-components/LandscapeOrientationWarning/LandscapeOrientationWarning.vue";

export default {
    name: "App",
    components: {
        LandscapeOrientationWarning,
    },
};
</script>

<style lang="scss" scoped>
#orientation-warning {
    display: none;
}

#main-content {
    height: 100%;
}

@media (max-height: 500px) and (orientation: landscape) and (hover: none) {
    #orientation-warning {
        display: block;
    }

    #main-content {
        display: none;
    }
}
</style>
