import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/rotate-device.svg'


const _hoisted_1 = { id: "landscape-orientation-warning" }
const _hoisted_2 = { id: "message-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("article", _hoisted_2, [
      _createVNode(_component_q_img, {
        src: _imports_0,
        id: "rotation-image",
        "no-spinner": ""
      }),
      _cache[0] || (_cache[0] = _createElementVNode("p", {
        id: "rotate-device-message-text",
        class: "q-mt-md"
      }, " Розверніть свій девайс у вертикальне положення ", -1))
    ])
  ]))
}