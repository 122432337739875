import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "mainView",
        component: () => import(/*webpackChunkName: "main-view-page" */ "../views/MainView.vue"),
    },
    {
        path: "/confirm",
        name: "confirmView",
        component: () => import(/*webpackChunkName: "confirm-view-page" */ "../views/ConfirmView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
