import { createI18n } from "vue-i18n";

export default createI18n({
    legacy: false,
    locale: "ua",
    fallbackLocale: "ua",
    messages: {
        ua: {
            error: {
                0: "Операція успішна",
                500: "Помилка. Спробуй пізніше",
                101: "Перевір дані картки, десь помилка",
                105: "Банк не підтвердив оплату. Оплати іншою карткою",
                108: "Оплати іншою карткою",
                111: "Не знайшли твою картку. Перевір номер або оплати іншою карткою",
                116: "Недостатньо коштів. Поповни картку",
                130: "Збільш ліміт або зменш суму платежу",
                290: "Помилка. Спробуй пізніше",
                291: "Технічна помилка. Спробуй пізніше",
                401: "Технічна помилка. Спробуй пізніше ",
                402: "Технічна помилка. Спробуй пізніше ",
                403: "Технічна помилка. Спробуй пізніше ",
                404: "Технічна помилка. Спробуй пізніше ",
                405: "Технічна помилка. Спробуй пізніше ",
                406: "Ти перевищив ліміт платежів",
                407: "Помилка. Оплати іншою карткою",
                408: "Помилка. Дані не передані. Спробуй пізніше",
                409: "Забагато операцій. Спробуй пізніше",
                410: "Замовлення вже оплачено",
                411: "Час вийшов. Ти не встиг підтвердити оплату",
                412: "Технічна помилка. Спробуй пізніше",
                413: "Перевір номер картки або оплати іншою",
                414: "Введи CVV-код. Це три цифри на звороті картки",
                420: "Технічна помилка. Спробуй пізніше",
                421: "Технічна помилка. Спробуй пізніше",
                430: "Технічна помилка. Спробуй пізніше",
                431: "Технічна помилка. Спробуй пізніше",
                432: "Технічна помилка. Спробуй пізніше",
                433: "Технічна помилка. Спробуй пізніше",
                434: "Технічна помилка. Спробуй пізніше",
                435: "Технічна помилка. Спробуй пізніше",
                436: "Ти перевищив ліміт суми платежів",
                437: "Забагато спроб. Сплати іншою карткою ",
                438: "Ми не працюємо з такою валютою",
                439: "Технічна помилка. Спробуй пізніше",
                440: "Вийшов час на авторизацію. Оплати іншою карткою",
                441: "Технічна помилка. Спробуй пізніше",
                442: "Технічна помилка. Спробуй пізніше",
                450: "Технічна помилка. Спробуй пізніше",
                451: "Технічна помилка. Спробуй пізніше",
                452: "Технічна помилка. Спробуй пізніше",
                460: "Технічна помилка. Спробуй пізніше",
                501: "Ти скасував платіж. Підтвердь оплату ще раз",
                502: "Занадто довгий сеанс. Наступного разу дій швидше",
                503: "Продавець скасував операцію",
                504: "Технічна помилка. Спробуй пізніше",
                505: "Технічна помилка. Спробуй пізніше",
                506: "Занадто довгий сеанс. Авторизуйся ще раз",
                507: "Технічна помилка. Спробуй пізніше",
                508: "Технічна помилка. Спробуй пізніше",
                509: "Технічна помилка. Спробуй пізніше",
                510: "Технічна помилка. Спробуй пізніше",
                511: "Технічна помилка. Спробуй пізніше",
                512: "Технічна помилка. Спробуй пізніше",
                601: "Операція не завершена",
                602: "Зачекай, виконується платіж",
                902: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                909: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                999: "Зачекай, обробляємо платіж",
                3000: "Помилка. Якщо вона повторюється - звернись до служби підтримки ",
                3001: "Помилка. Якщо вона повторюється - звернись до служби підтримки ",
                3002: "Помилка при спробі повернення платежу",
                3003: "Перевір номер картки. Ця картка не існує або термін її дії  скінчився",
                3004: "Помилка. Перевір реквізити картки",
                3005: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                3006: "Перевищено час на повернення платежу",
                3007: "Платіж неможливо відмінити або повернути",
                3008: "Статус платежу не дозволяє відміну або повернення",
                3009: "Невірний parent документ",
                3010: "Невірний AuthType при поверненні",
                3011: "Перевищено ліміт на списання",
                3012: "Недостатньо коштів. Поповни картку",
                3013: "Помилка. Якщо вона повторюється - звернись до служби підтримки ",
                3014: "Помилка. Якщо вона повторюється - звернись до служби підтримки ",
                3015: "Сума всіх повернень перевищує суму оригінального замовлення",
                3016: "Повернення на іншу картку можна провести тільки наступного бізнес-дня",
                3017: "Картка відсутня або термін зберігання вийшов",
                3020: "Помилка при перевірці реквізитів відправника або отримувача",
                4000: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4001: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4002: " Повернись до магазину та оплати іншою карткою",
                4003: "Помилка. Спробуй пізніше",
                4004: "Час вийшов. Наступного разу підтверджуй швидше ",
                4005: "Замовлення не знайдено",
                4006: "Платіжний шлюз недоступний",
                4007: "Торговець недоступний або вказаний невірно",
                4008: "storeUrl магазину не відповідає",
                4009: "Термін дії токена картки вичерпано",
                4010: "Токен картки не знайдено або належить іншому замовленню",
                4011: "По вказаному токену замовлення вже оплачене",
                4012: "Технічна помилка. Оплати іншою карткою",
                4013: "Ти скасував платіж",
                4014: "Замовлення вже оплачено",
                4015: "Клієнта визначити неможливо",
                4016: "Помилка при розрахунку балів",
                4017: "Технічна помилка. Оплати іншою карткою",
                4020: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4021: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4022: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4023: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4024: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4025: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4026: "Помилка. Спробуй пізніше",
                4027: "Помилка. Спробуй пізніше",
                4028: "Помилка при перевірці номеру телефону",
                4029: "Статус замовлення не дозволяє виконати поточну операцію",
                4030: "Застарілі дані замовлення",
                4031: "За даною транзакцією платіж не знайдено",
                4032: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4033: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4034: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4035: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4036: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4037: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4038: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4039: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4040: "Шаблон повідомлення не знайдено",
                4041: "За даним номером телефону повідомлення вже відправлено",
                4042: "Замовлення в процесі оплати",
                4043: "За даною транзакцією платіж не знайдено",
                4044: "Помилка при виконанні перевірки 3DSecure",
                4045: "Помилка при виконанні перевірки 3DSecure",
                4046: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4047: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4048: "Помилка. Якщо вона повторюється - звернись до служби підтримки",
                4049: "Помилка при отриманні даних картки",
                4050: "Помилка при збереженні картки",
                4051: "Помилка при отриманні даних картки по токену",
                4052: "Номер телефону з пуш повідомлення відрізняється від номеру в замовленні",
                4053: "Помилка валідації сесії ApplePay",
                4054: "Відсутні параметри конфігурації мерчанта",
                4055: "Помилка при формуванні номеру квитанції",
                4056: "Помилка при відправлені листа",
                4057: "Розщеплення платежів недоступне для вказаного мерчанта",
                4058: "Для преавторизації розщеплення не дозволено",
                4059: "Відсутні дані для розщеплення платежу",
                4060: "Для поточного замовлення вже існує розщеплений платіж із вказаним отримувачем",
                4061: "Статус замовлення не дозволяє виконати операцію",
                4062: "Серед розщеплених платежів є декілька записів з однаковим отримувачем",
                4063: "Сума всіх розщеплених платежів  перевищує суму замовлення",
                4064: "Сума повернення більше суми збереженого розщепленого платежу",
                4065: "Статус розщепленого платежу не дозволяє виконати операцію",
                4066: "Мерчант не існує або не доступний поточному партнеру",
                4067: "Транзакція вже виконана",
                4068: "Картка не підтримується. Оплати іншою карткою.",
                4069: "Посилання не існує або недоступно",
                4070: "Посилання не існує або недоступно",
                4071: "Помилка при оновленні посилання",
                4072: "Помилка при отриманні даних отримувача",
                4073: "Невірна сума переказу",
                4074: "Транзакцію не знайдено",
                4075: "Перевищено максимальний час на проведення транзакції",
                4076: "Статус транзакції не дозволяє виконати поточну операцію",
                4077: "Транзакція в процесі виконання",
                4078: "Відсутні дані для проведення 3DSecure ініціалізації",
                4079: "Помилка при перевірці картки",
                4080: "Картка даної країни не підтримується",
                4081: "Даний метод доступний лише для PCI DSS мерчантів",
                4082: "Невалідний номер картки",
                4083: "Помилка в контрольній сумі картки",
                4084: "Невалідний формат cvv",
                4085: "Термін дії картки вийшов",
                4086: "Відсутні дані для завершення токенізації",
                4087: "Токен картки не знайдено",
                4088: "Токен картки належить іншому партнеру",
                4089: "Токен картки деактивований",
                4090: "Токен картки належить іншому замовленню",
                4091: "Помилка при формуванні PDF файлу",
                4092: "Помилка при отриманні даних картки по токену",
                4093: "Спосіб оплати MasterPass недоступний",
                4094: "Інформацію не знайдено",
                4095: "Помилка при отриманні змін поточного касира",
                4096: "Помилка при відкритті нової зміни",
                4097: "Помилка при отриманні інформації про зміну",
                4098: "Помилка при створенні чеку",
                4099: "Помилка входу користувача",
                4100: "Помилка. Зміна не перейшла у фінальний статус",
                4101: "Фіскалізація в процесі обробки",
                4102: "Фіскалізація вже виконана",
                4103: "Помилка ідентифікації картки",
                4104: "Помилка отримання номеру картки",
                4105: "Помилка отримання номеру картки",
                4106: "Невідома картка",
                4107: "Помилка ідентифікації картки",
                4108: "Розщеплення платежів недоступне для даного типу авторизації",
                4109: "В замовленні відсутні розщеплені платежі",
                4110: "В замовленні відсутній розщеплений платіж по вказаному сабмерчанту",
                4111: "Сума повернення відрізняється від суми розщеплених платежів",
                4112: "Серед розщеплених платежів вже є відмінений",
                4113: "В запиті є розщеплений платіж з недопустимою сумою",
                4114: "Збереження картки недоступне для даного типу авторизації",
                4115: "В запиті відсутній ідентифікатор клієнта",
                4116: "Збереження картки недоступне для даного типу оплати",
                4117: "Картку не знайдено",
                4118: "Картка не допускається",
                4119: "Невірний ключ ліцензії каси",
                4120: "Невірний пінкод",
                4121: "Невірний токен доступу",
                4122: "Доступ заборонено, оскільки касира деактивовано",
                4123: "Касира заблоковано",
                4124: "Термін дії ключа закінчився",
                5000: "Технічна помилка. Оплати іншою карткою",
                5001: "Технічна помилка. Оплати іншою карткою",
                5002: "Технічна помилка. Оплати іншою карткою",
                5004: "Номер телефону не заповнений або заповнений невірно",
                5005: "Невірна сума транзакції",
                5009: "Відсутні дані токенізації",
                5011: "Токенізація вже завершена",
                5012: "Токенізація в процесі",
            },
        },
        en: {
            error: {},
        },
    },
});
